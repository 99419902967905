<template>
  <div>ff</div>
</template>

<script>
export default {
  name: 'Logout',

  mounted() {
    this.$store.dispatch('init')
    delete localStorage.loginToken
    localStorage.clear()
    this.$router.push({
      name: 'Login',
      params: this.$route.params,
      query: this.$route.query
    })
  }
}
</script>

<style scoped lang="scss"></style>
